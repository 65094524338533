(function($) {
    $.widget('ogn.parallaxjumbotron', {
        _create: function() {
            this.currentBreakPoint = breakpoint();
            this.offsetNavigationHeight = 80;
            this._buildScenes();
        },
        _init: function() {
            this.window.on('resize', this._onWindowResize.bind(this));
        },
        _onWindowResize: function() {
            var bp = breakpoint();
            this.actionChangeToSmall = (bp === 'md' || bp === 'sm' || bp === 'xs') && (this.currentBreakPoint === 'lg' || this.currentBreakPoint === 'xl');
            this.actionChangeToLarge = (bp === 'lg' || bp === 'xl') && (this.currentBreakPoint === 'md' || this.currentBreakPoint === 'sm' || this.currentBreakPoint === 'xs');
            this.actionUpdateComponent = bp === 'lg' || bp === 'xl';
            if (this.actionChangeToSmall || this.actionChangeToLarge) {
                this.currentBreakPoint = bp;
            }
            if (this.actionChangeToSmall) {
                this.handScene.removePin(true);
                this.handScene.removeTween(true);
                this.handScene.refresh();
                scrollController.removeScene(this.handScene);
                scrollController.removeScene(this.calloutScene);
                this.element.css('height', 'auto').css('margin-bottom', 0);
                $('.callout-holder > .animated-element', this.element).css('opacity', 'initial')
                return;
            } else if (this.actionChangeToLarge) {
                this._buildScenes();
                return;
            } else if (this.actionUpdateComponent) {
                var height = this.window.height() - offsetNavigationHeight;
                this.handScene.duration(height);
                this.handScene.triggerHook(triggerHook());
                this.handScene.update(true);
                this.element
                    .css('width', this.window.width())
                    .css('height', height)
                    .css('margin-bottom', height)
                    .parent()
                    .css('min-height', height)
                    .css('height', height)
                    .css('margin-bottom', height);
                this.handScene.refresh();
                this.calloutScene.offset(this._calloutOffset(height));
                this.calloutScene.triggerHook(triggerHook());
                this.calloutScene.update(true);
                return;
            }
        },
        _buildScenes: function() {
            if (this.currentBreakPoint === 'xl' || this.currentBreakPoint === 'lg') {
                var height = this.window.height() - offsetNavigationHeight;
                this.element
                    .height(height)
                    .css('margin-bottom', height);

                var handTween = TweenMax.fromTo($('.image-holder', this.element), {y: '0', x: '0'}, {y: '15%', x: '100%', ease: Linear.easeNone });
                this.handScene = new ScrollMagic.Scene({
                    triggerElement: this.element,
                    triggerHook: triggerHook(),
                    duration: height,
                })
                    .setPin(this.element, {pushFollowers: false})
                    .setTween(new TimelineMax().add([handTween]))
                    .addTo(scrollController);
                this.calloutScene = new ScrollMagic.Scene({
                    triggerElement: this.element,
                    triggerHook: triggerHook(),
                    offset: this._calloutOffset(height)
                })
                    .setClassToggle($('.callout-holder', this.element), 'visible')
                    .addTo(scrollController);
            }
        },
        _calloutOffset: function(height) {
            var bp = breakpoint();
            switch(bp) {
                case 'xl':
                    return height * 0.45;
                case 'lg':
                default:
                    return height * 0.5;
            }
        }
    });
    // Intro Parallax Component
    $.widget('ogn.parallaxintro', {
        _create: function() {
            new ScrollMagic.Scene({
                triggerElement: this.element,
                triggerHook: 0.8,
            })
            .setClassToggle(this.element, 'visible')
            .addTo(scrollController);
        }
    });
    // Columns Parallax Component
    $.widget('ogn.parallaxcolumns', {
        _create: function() {
            this.currentBreakPoint = breakpoint();
            this.$heading = $('.headings-holder', this.element);
            this.$content = $('.contents-holder', this.element);
            this.$items = $('.contents-item', this.element);

            var height = this.window.height() - offsetNavigationHeight;
            this.element.height(height).css('margin-bottom', height);
            this.$items.height(height);
            this.$heading.height(height);
            this.scene = new ScrollMagic.Scene({
                triggerElement: this.element,
                triggerHook: triggerHook(),
                duration: height,
            })
                .setPin(this.element, {pushFollowers: false})
                .addTo(scrollController);
            this.switchScene = new ScrollMagic.Scene({
                triggerElement: this.element,
                triggerHook: triggerHook(),
                offset: height * 0.5
            })
                .setClassToggle(this.element, 'next')
                .addTo(scrollController);
        },
        _init: function() {
            this.window.on('resize', this._onWindowResize.bind(this));
        },
        _onWindowResize: function() {
            var height = this.window.height() - offsetNavigationHeight;
            this.$heading
                .css('height', height);
            this.$items
                .css('height', height);
            this.scene.duration(height);
            this.scene.triggerHook(triggerHook());
            this.scene.update(true);
            this.element
                .css('width', this.window.width())
                .css('height', height)
                .css('margin-bottom', height)
                .parent()
                .css('min-height', height)
                .css('margin-bottom', height)
                .css('height', height);
            this.scene.refresh();
            this.switchScene.triggerHook(triggerHook());
            this.switchScene.offset(height * 0.5);
            this.switchScene.update(true);
            this.switchScene.refresh();
        },
    });
    // Contact component parallax
    $.widget('ogn.parallaxcontact', {
        _create: function() {
            var height = this.window.height() - offsetNavigationHeight;
            this.element.height(height).css('margin-bottom', height * 0.5);
            this.scene = new ScrollMagic.Scene({
                triggerElement: this.element,
                triggerHook: triggerHook(),
                duration: height * 0.5,
            })
                .setPin(this.element, {pushFollowers: false})
                .addTo(scrollController);
        },
        _init: function() {
            this.window.on('resize', this._onWindowResize.bind(this));
        },
        _onWindowResize: function() {
            var height = this.window.height() - offsetNavigationHeight;
            this.scene.duration(height * 0.5);
            this.scene.triggerHook(triggerHook());
            this.scene.update(true);
            this.element
                .css('width', this.window.width())
                .css('height', height)
                .css('margin-bottom', height * 0.5)
                .parent()
                .css('min-height', height)
                .css('height', height)
                .css('margin-bottom', height * 0.5);
            this.scene.refresh();
        },
    });

    // Entry point
    $(document).ready(function() {
        $('.template-home #parallax-jumbotron').parallaxjumbotron();
        $('.template-home .component-intro').parallaxintro();
        $('.template-home .component-animated-columns').parallaxcolumns();
        $('.template-home .component-contact').parallaxcontact();
    });

    // Helpers Section
    var offsetNavigationHeight = 80;
    var scrollController = new ScrollMagic.Controller({
        //addIndicators: true
    });
    var breakpoint = function() {
        var width = $(window).width();
        var breakpoint = 'xs';
        if (width >= 576) {
            breakpoint = 'sm';
        }
        if (width >= 768) {
            breakpoint = 'md';
        }
        if (width >= 992) {
            breakpoint = 'lg';
        }
        if (width >= 1200) {
            breakpoint = 'xl';
        }
        return breakpoint;
    };
    var triggerHook = function() {
        return 80 / $(window).height();
    };
})(jQuery);