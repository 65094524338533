$(function() {
	//
	if ($('#video-dr-george-koullias').length) {
		var player = videojs('video-dr-george-koullias');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true,
			'90': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'Dr. George Koullias Video', 'value':'START'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'Dr. George Koullias Video', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'Dr. George Koullias Video', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'Dr. George Koullias Video', 'value':'COMPLETE'});
		});
	}
});